import { useQuery } from "@apollo/client";
import { gql } from "__generated__";

const GET_PROFILE = gql(`
  query GetProfile {
    profile {
      user_id
    }
  }
`);

const useProfile = () => {
  const { data } = useQuery(GET_PROFILE);

  const profile = data?.profile;

  return profile;
};

export default useProfile;
