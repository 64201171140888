import useAuth from "hooks/useAuth";
import { Nav } from "react-bootstrap";

const SignOutMenu: React.FC = (props) => {
  const { signOut } = useAuth();
  return (
    <Nav {...props}>
      <Nav.Link
        onClick={() => {
          signOut();
        }}
      >
        signOut
      </Nav.Link>
    </Nav>
  );
};

export default SignOutMenu;
