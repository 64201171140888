import styled from "@emotion/styled";
import { Link, NavLink } from "react-router-dom";

export const NotStyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  :active,
  :hover {
    color: inherit;
  }
`;

export const NotStyledNavLink = styled(NavLink)`
  text-decoration: none;
  color: inherit;
  :active,
  :hover {
    color: inherit;
  }
`;
