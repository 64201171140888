import { Nav } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const SignInMenu: React.FC = (props) => {
  const navigate = useNavigate();
  return (
    <Nav {...props}>
      <Nav.Link onClick={() => navigate("/auth/signin")}>signIn</Nav.Link>
    </Nav>
  );
};

export default SignInMenu;
